<div class="route-header">
  <div *ngIf="displayInfo?.onHold?.length" class="bottom15">
    <span class="warning">Attention:</span> Some shipments on this route are currently <code>On Hold</code>:
    <nz-tag *ngFor="let s of displayInfo.onHold">{{ s }}</nz-tag>
  </div>
  <div class="header-row">
    <div class="item">
      <div *ngIf="displayInfo.isGhostLoad" class="ghost-load-label">{{ getLabelGhostLoad() }}</div>
      <div class="route-label">{{ displayInfo.clientText }} {{ displayInfo.clientText ? '-' : '' }} {{displayInfo.code}}</div>
      <div class="route-link flex">
        <div>
          <a *ngIf="jobHyperLink" #jobLink [routerLink]="[jobHyperLink.hyperLinkUrl]" target="_blank"
            style="height:0;font-size:0;border: 0;">{{jobHyperLink.hyperLinkText}}</a>
        </div>
        <div>
          <a (click)="onBtnCopyRouteLink()" nz-tooltip nzTooltipTitle="Copy link for internal WARP team"><i
              class="icon link"></i></a>
        </div>
      </div>
      <div class="route-status {{displayInfo.status}}">{{displayInfo.status}}</div>
    </div>
    <div class="item">
      <div class="link-icon driver-location" (click)="onBtnAddDriverLocation()">
        <div class="icon driver-location"></div>
        Driver Location
      </div>
      <div class="actions">

        <a nz-dropdown [nzDropdownMenu]="menu">
          <div class="link-icon">
            <div class="icon action"></div>
            Action
          </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <ng-container *ngIf="!displayInfo?.isGhostLoad">
              <li nz-menu-item (click)="onClickAction('edit-sequence')">
                <span nz-icon nzType="node-index" nzTheme="outline" style="margin-right: 7px;"></span>
                Edit Sequence
              </li>
              <li nz-menu-item (click)="onClickAction('adjust-shipment')">
                <span nz-icon nzType="node-expand" nzTheme="outline" style="margin-right: 7px;"></span>
                Add/Remove Shipments
              </li>
              <li nz-menu-item (click)="onClickAction('merge-route')">
                <span nz-icon nzType="merge-cells" nzTheme="outline" style="margin-right: 7px;"></span>
                Merge Route
              </li>
              <li nz-menu-item (click)="onClickAction('split-route')">
                <span nz-icon nzType="fork" nzTheme="outline" style="margin-right: 7px;"></span>
                Split Route
              </li>
              <li nz-menu-item (click)="onClickAction('show-basic-info')">
                <span nz-icon nzType="info" nzTheme="outline" style="margin-right: 7px;"></span>
                Show basic route info
              </li>
              <li nz-menu-divider></li>
              <!--<li nz-menu-item (click)="onClickAction('confirm-all-pods')">
                <span nz-icon nzType="verified" nzTheme="outline" style="margin-right: 7px;"></span>
                Confirm All PODs
              </li> -->
              <li nz-menu-item nzDanger (click)="onClickAction('cancel-route')">
                <span nz-icon nzType="delete" nzTheme="outline" style="margin-right: 7px;"></span>
                Cancel Route
              </li>
            </ng-container>
            <ng-container *ngIf="displayInfo?.isGhostLoad">
              <li nz-menu-item (click)="onClickAction('adjust-shipment')">
                <span nz-icon nzType="node-expand" nzTheme="outline" style="margin-right: 7px;"></span>
                Add Shipments
              </li>
              <li nz-menu-item nzDanger (click)="onClickAction('cancel-ghost-load')">
                <span nz-icon nzType="delete" nzTheme="outline" style="margin-right: 7px;"></span>
                Cancel Route
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div class="link-icon driver-info" (click)="onBtnAssignPiC()">
        <div class="icon account"></div>
        {{displayInfo.picName}}
      </div>
    </div>
  </div>
  <div class="header-row">
    <div class="item">
      <div class="route-overview" *ngIf="displayInfo.firstLocation">
        <img src="/assets/img/dispatch-icons/route.svg" />
        <span class="left5">{{displayInfo.firstLocation}} - {{displayInfo.lastLocation}}</span>
      </div>
    </div>
    <!-- <div class="item" *ngIf="latestEta">
      <div class="eta-info">
        <b>ETA: {{ latestEta }} </b>
      </div>
    </div> -->
  </div>
  <div class="header-row">
    <div class="item">
      <div class="distance-time">
        {{ mileage }} mi - {{ hours }} hr
      </div>
    </div>
    <div class="item">
      <div class="flex" style="align-items: center;">
        <div>Product Type: {{ displayInfo.classificationSettings?.productType || 'N/A'}}</div>
        <div>
          <button class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClassification()">Update</button>
        </div>
      </div>
    </div>
  </div>
  <div class="header-row">
    <div class="item">
      <div class="flex">
        <div style="margin-right: 50px;">
          <span *ngIf="displayInfo.equipments">{{displayInfo.equipments}}</span>
          <span *ngIf="!displayInfo.equipments">Equipment: N/A</span>
          <button *ngIf="canEditEquipment" class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateEquipment()">Update</button>
        </div>
        <div *ngIf="displayInfo.tempRange" style="align-self: center;">{{displayInfo.tempRange}}</div>
      </div>
    </div>
    <div class="item">
      <div class="flex" style="align-items: center;">
        <div>Shipment Classification: {{ displayInfo.classificationSettings?.classificationShipment || 'N/A'}}</div>
        <div>
          <button class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateClassification()">Update</button>
        </div>
      </div>
    </div>
  </div>
  <!--  Driver Down Time-->
  <div class="header-row">
    <div *ngIf="displayInfo.carrier" class="item">
      <div>
        <div class="flex" style="align-items: center">
          <div class="driver-down-time-icon"><img src="/assets/img/dispatch-icons/hotel.svg" /></div>
          <div class="label" style="align-self: center;margin-left: 7px">
            <div *ngIf="displayInfo.driverDownTime?.from && displayInfo.driverDownTime?.to"
                 nz-tooltip="Driver Downtime"
                 class="flex">
              <div>
                <span class="medium">{{displayInfo.driverDownTime.formated}}
                  {{displayInfo.driverDownTime.timezoneShort}}</span>
              </div>

            </div>
            <div *ngIf="!displayInfo.driverDownTime?.from" class="flex">
              Driver Downtime
            </div>
          </div>
          <button class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnEditDriverDownTime()">Update</button>
        </div>
      </div>
    </div>
    <div *ngIf="isJobCompleted" class="item flex">
      <div app-rating
        [titleHeader]="'Rate this route'"
        [model]="displayInfo.rating"
        [onSubmit]="saveDataRatingRoute(displayInfo.id)"
        (onDone)="refreshService()">
      </div>
    </div>
  </div>
  <!--  End Driver Down Time-->
  <div class="messageWarning" *ngIf="isHasTrackingTask">
    <ng-container>
      <div class="btn-alert flex"><i class="icon warning"></i>{{trackingTaskWarning}}</div>
      <button *ngIf="isShowBtnSNOOZE" nz-button (click)="onBtnSnoozeTrackingTask()" class="btn-snooze">
        <i class="icon snooze"></i>
        SNOOZE
      </button>
      <button *ngIf="!isArchived" nz-button (click)="onBtnUpdateArchiveStatus(displayInfo.id, 'archive')">
        <i nz-icon nzType="tag" nzTheme="outline"></i>
        Archive
      </button> 
      <button *ngIf="isArchived" nz-button (click)="onBtnUpdateArchiveStatus(displayInfo.id, 'un-archive')">
        <i nz-icon nzType="rollback" nzTheme="outline"></i>
        Un-archive
      </button>
    </ng-container>
    <!-- <ng-container *ngIf="issueType === 'reviewActuals'">
      <button nz-button (click)="onBtnUpdateTime()" class="btn-alert">
        <i class="icon warning"></i>
        {{alertMessage}}
      </button>
    </ng-container>
    <ng-container *ngIf="issueType === 'addReasonCode'">
      <button nz-button (click)="onBtnUpdateReasonCode()" class="btn-alert">
        <i class="icon warning"></i>
        {{alertMessage}}
      </button>
    </ng-container> -->
  </div>

  <!-- <div *ngIf="isSnoozed" class="flex group-snoozed">
    <div class="flex group-item">
      <i class="icon snooze-blue"></i>
      <span>
        SNOOZED - {{alertMessage}}
      </span>
    </div>

    <span countdown-timer [time]="snoozeTime" (refreshDispatchService)="refreshService()"
      style="color: #1E40AF; margin-left: 24px;">
    </span>
  </div> -->
</div>
<div class="timeline-bar">
  <!-- timeline bar here -->
</div>
