<ng-template [nzModalTitle]>
  <div class="component-header center-vertical flex-space-between">
    <div class="center-vertical">
      <div class="modal-close" (click)="closeDialog()">
        <i nz-icon nzType="close"></i>
      </div>
      <div class="modal-title" style="margin-left: 12px;">Filters</div>
    </div>
    <div class="btn-action">
      <button nz-button nzType="default" nzDanger (click)="onBtnResetFilter()">Reset</button>
      <button nz-button nzType="primary" style="margin-left: 16px;" (click)="onBtnFilters()">Apply</button>
    </div>
  </div>
</ng-template>
<div>
  <form [formGroup]="formInput" nz-form>
    <div class="form-label">Customers, Carriers, Pickup/Delivery States</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['clientId']" class="form-item" [ngClass]="key" #customerFilter>
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <select-by-searching
            [version]="2"
            nzMode="multiple"
            [formControlName]="key"
            [showSubClient]="true"
            [placeholder]="getPlaceHolder(key)"
            dropdownClassName="dispatch-filter-customer"
            (ngModelChange)="onChange($event, key)"
          >
          </select-by-searching>
        </div>
      </div>

      <div nz-col nzSm="6" nzMd="6" *ngFor="let key of ['carrierId']" class="form-item" [ngClass]="key">
        <div>
          <div class="form-label-v2">{{getLabel(key)}}</div>
          <nz-select
            nzBackdrop="true"
            nzAllowClear
            nzShowSearch
            [formControlName]="key"
            [nzLoading]="isFetchingCarrier"
            [nzPlaceHolder]="getPlaceHolder(key)"
            nzDropdownClassName="dispatch-filter-carrier"
            style="width: 100%"
            (ngModelChange)="onChangeCarrier($event, key); onChange($event, key)"
          >
            <nz-option-group nzLabel="Filter by assignment status">
              <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
              <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
            </nz-option-group>
            <nz-option-group nzLabel="Filter by specific carrier">
              <nz-option
                nzCustomContent
                *ngFor="let item of listCarriers"
                [nzValue]="item.id"
                [nzLabel]="getCarrierNameWithMCandDOT(item)"
              >
                <div>{{getCarrierName(item)}}</div>
                <div *ngIf="item?.basicInfo?.mc" class="sub-info">MC: {{getCarrierMC(item)}}</div>
                <div *ngIf="item?.basicInfo?.dot" class="sub-info">DOT: {{getCarrierDOT(item)}}</div>
              </nz-option>
            </nz-option-group>
          </nz-select>
        </div>
      </div>

      <div class="form-item" [ngClass]="key" *ngFor="let key of ['pickupState']" nz-col nzSm="6" nzMd="6">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container
          [ngTemplateOutlet]="tplSelectState"
          [ngTemplateOutletContext]="{key, form: formInput}"
        ></ng-container>
      </div>

      <div class="form-item" [ngClass]="key" *ngFor="let key of ['dropoffState']" nz-col nzSm="6" nzMd="6">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container
          [ngTemplateOutlet]="tplSelectState"
          [ngTemplateOutletContext]="{key, form: formInput}"
        ></ng-container>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Date Range & Equipment</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['fromDate']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
          <nz-date-picker
            style="width: 100%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
        </nz-input-group>
      </div>
      <div *ngFor="let key of ['toDate']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-input-group [nzAddOnAfter]="getTimezoneShort()">
          <nz-date-picker
            style="width: 100%"
            [formControlName]="key"
            nzFormat="yyyy-MM-dd"
            (ngModelChange)="onChange($event, key)"
          >
          </nz-date-picker>
        </nz-input-group>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label" style="display: flex;">Equipment, Shipment Type <div *ngIf="urlMyLoad != 'my_load'" style="margin-left: 3px;"> & Dispatcher</div></div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['vehicleType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-form-item>
          <nz-form-control>
            <div vehicle-selector [value]="getVehicleType()" (valueChange)="onChange($event, key)" [quoting]="false" [withOptions]="false"></div>
            <!-- <app-form-equipments [formControlName]="key" (ngModelChange)="onChange($event, key)"></app-form-equipments> -->
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngFor="let key of ['shipmentType']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          style="width: 100%"
          [formControlName]="key"
          [nzPlaceHolder]="getPlaceHolder(key)"
          (ngModelChange)="onChange($event, key)"
        >
          <nz-option *ngFor="let item of listShipmentTypes" [nzValue]="item" [nzLabel]="item"> </nz-option>
        </nz-select>
      </div>
      <div *ngFor="let key of ['picId']" nz-col nzSm="6" nzMd="6" class="form-item" class="{{'display-dispatcher-' + setShowDispatcher()}}">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select
          nzBackdrop="true"
          nzAllowClear
          nzShowSearch
          style="width: 100%"
          [formControlName]="key"
          [nzLoading]="isFetchingCarrier"
          [nzPlaceHolder]="getPlaceHolder(key)"
          nzDropdownClassName="dispatch-filter-dispatcher"
          (ngModelChange)="onChangeDispatcher($event, key)"
        >
          <nz-option-group nzLabel="Filter by assignment status">
            <nz-option nzValue="no" nzLabel="Not assigned yet"></nz-option>
            <nz-option nzValue="yes" nzLabel="Already assigned"></nz-option>
          </nz-option-group>
          <nz-option-group nzLabel="Filter by apecific dispatcher">
            <nz-option
              *ngFor="let item of listDispatcherForDropdown"
              [nzValue]="item.id"
              [nzLabel]="dispatcherName(item)"
            >
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Sales Rep</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div *ngFor="let key of ['carrierSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingCarrierSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
           style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific carrier sales rep">
            <nz-option *ngFor="let item of listCarrierSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientSaleRepId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
          style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client seller">
            <nz-option *ngFor="let item of listClientSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientServiceUserId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
          [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
          [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
          style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client account owner">
            <nz-option *ngFor="let item of listClientSales"
              [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
      <div *ngFor="let key of ['clientServiceRepUserId']" nz-col nzSm="6" nzMd="6" class="form-item">
        <div class="form-label-v2">{{getLabel(key)}}</div>

        <nz-select nzAllowClear nzShowSearch
                   [formControlName]="key" [nzLoading]="isFetchingClientSaleRep"
                   [nzPlaceHolder]="getPlaceHolder(key)" nzDropdownClassName="dispatch-filter-carrier-sale-rep"
                   style="width: 100%;" (ngModelChange)="onChange($event, key)">
          <nz-option-group nzLabel="Filter by specific client service rep">
            <nz-option *ngFor="let item of listClientSales"
                       [nzValue]="item.id" [nzLabel]="getFullName(item)">
            </nz-option>
          </nz-option-group>
        </nz-select>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div class="form-label">Tracking Tasks</div>
    <div *ngFor="let key of ['archivedTrackingTask']" nz-col nzSm="6" nzMd="6" class="form-item">
      <div class="form-label-v2">{{getLabel(key)}}</div>

      <nz-select nzAllowClear nzShowSearch
        [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)"
        style="width: 100%;" (ngModelChange)="onChange($event, key)">
        <nz-option [nzLabel]="'Show tasks'" [nzValue]="false"></nz-option>
        <nz-option [nzLabel]="'Show tasks archived'" [nzValue]="true"></nz-option>
      </nz-select>
    </div>

    <div class="separator h bottom10"></div>
    <div class="form-label">Others</div>
    <div nz-row [nzGutter]="{md:32}" class="form-group">
      <div class="form-item" style="margin-left: 16px;">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px" class="{{'display-dispatcher-' + setShowDispatcher()}}">
          <label nz-checkbox [(ngModel)]="needDispatcherChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedDispatcher($event)">Need Dispatcher</label>
        </div>
      </div>

      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needCarrierChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedCarrier($event)">Need Carrier</label>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needDriverChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedDriver($event)">Need Driver</label>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="needPODChecked" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxNeedPOD($event)">Need POD</label>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label-v2"></div>
        <div style="padding-top: 5px">
          <label nz-checkbox [(ngModel)]="isWalmartScheduledLessThanDuration" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckboxWalmartFilter($event)">Walmart 4-hours</label>
        </div>
      </div>
    </div>
    <div *ngIf="lastUpdated">
      <div class="last-updated">Request ID: {{lastUpdatedTime}} [{{requestId}}]</div>
    </div>
  </form>
</div>
<ng-template #tplSelectState let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      style="width: 100%"
      nzShowSearch
      nzAllowClear
      nzBackdrop="true"
      [nzPlaceHolder]="getPlaceHolder(key)"
      [formControlName]="key"
      nzDropdownClassName="dispatch-filter-state"
      (ngModelChange)="onChange($event, key)"
    >
      <nz-option-group *ngFor="let country of countriesStates" [nzLabel]="country.name">
        <nz-option
          *ngFor="let state of country.states"
          nzValue="{{country.code}}_{{state.code}}"
          [nzLabel]="getStateDesc(state)"
        ></nz-option>
      </nz-option-group>
    </nz-select>
  </ng-container>
</ng-template>
