import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@services/input-helper";
import { DispatchService } from "../../dispatchService";
import RouteEntity from "../../entity/RouteEntity";
import { DialogService } from "@dialogs/dialog.service";
import { DispatchAssignCarrierAndDriver } from "../assign-carrier";
import { AddTrailerNumber } from "./add-trailer-number/add-trailer-number";
import { DriverDownTime } from "./driver-down-time";
import { AddCustomTracking } from "./add-custom-tracking";
import { AddReferenceNumber } from "./add-reference-number";
import { CarrierCost } from "@wearewarp/types/data-model/types/AssignedCarrier";
import { DispatchLiveTracking } from "../../dispatch-live-tracking";
import { ModalHelper } from "@wearewarp/ng-antd";
import { DriverAppStatus } from "../../dispatch-live-tracking/driver-app-status/component";
import { StringULID } from "@wearewarp/types";
import { Utils } from "@services/utils";
import { Log } from "@services/log";
import { CarrierCostHelper } from "../carrier-cost/helper";
import { UIHelper } from "@services/UIHelper";
import { FormDataCarrierPaymentPlanning } from "./carrier-payment-planning";
import { CarrierCostIssueNotificationRecipient } from "./cost-issue/interface";
import {AddCarrierSaleRep} from "@app/admin/dispatch/components/sales/add-carrier-sale-rep";

@Component({
  selector: '[dispatch-carrier-and-driver]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class DispatchCarrierAndDriver extends BaseComponent {

  public isLoading = true;
  public displayInfo: any = {};
  protected route: RouteEntity;
  public driverOtp: string = ''

  isDriverOnline = false;
  private driverId: string;
  private secondaryDriverId: string;
  private carrierId: string;
  carrierSalesRep: any;
  public saleRepEmails: CarrierCostIssueNotificationRecipient[] = [];
  private saleRepId: StringULID;
  private clientSalesRepId: StringULID
  private carrierSalesRepId: StringULID;
  get isShowSecondaryDriver (): boolean {
    return !!(this.route.getRequiredVehicle()?.options ?? []).includes('TEAM');
  };

  get jobId() { return this.route.getId() }

  get canAccessFinancialIssues(): boolean {
    return this.isAccounting || this.saleRepEmails.map(it => it.email).includes(this.authUser.email);
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected dispatchService: DispatchService,
    private dispatchLiveTracking: DispatchLiveTracking,
    private modalHelper: ModalHelper,
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(async () => {
        this.route = this.dispatchService.getRoute();
        this.displayInfo = {};
        let clients = this.route?.getClients() || [];
        for (let client of clients) {
          if (client?.saleUserId) this.saleRepId = client.saleUserId;
          if (client?.customerServiceUserId) this.clientSalesRepId = client.customerServiceUserId;
        }
        const carrierSalesRep = this.route?.getCarrierSalesRep();
        this.carrierSalesRepId = carrierSalesRep?.id;
        await this.getUserInfo();
        this.carrierId = this.route.getCarrierId();
        this.carrierSalesRep = this.getFullName(this.route?.getCarrierSalesRep());
        this.driverId = this.route.getDriverId();
        this.secondaryDriverId = this.route.getSecondaryDriverId();
        this.subscription.add(this.dispatchLiveTracking.trackDriverOnline(this.driverId, (value: boolean) => {
          Log.d(`driver ${this.driverId} ${value ? "online" : "offline"}`);
          this.isDriverOnline = value;
        }));
        await this.getData();
        this.buildDisplayInfo();
      })
    )
    this.getDriverOtp()
  }

  onBtnUpdateCarrierSalesRep() {
    const jobId = this.route?.getId();
    const carrierSalesRepId = this.route?.getCarrierSalesRep()?.id;
    DialogService.openFormDialog1(AddCarrierSaleRep, {
      nzComponentParams: {
        jobId: jobId,
        carrierSalesRepId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

  async getUserInfo() {
    let urls = [];
    const ids = Utils.uniqElementsArray([this.carrierSalesRepId, this.saleRepId, this.clientSalesRepId].filter(it => it));
    for(let id of ids) {
      urls.push(`${Const.APIURI_USERS}/${id}`);
    }
    if (!urls.length) return;
    //không sử dụng concurrentGET vì nếu có 1 user bị xoá thì sẽ ảnh hưởng đến toàn bộ các query khác.
    const result = await Promise.allSettled(urls.map(url => this.api.GET(url).toPromise()))
    let resp = result.filter(item => item.status == 'fulfilled').map((item: any) => item.value.data);
    for (let item of resp) {
      const roles: string[] = [];
      if (item.id == this.carrierSalesRepId) {
        roles.push('Carrier Sales Rep');
      }
      if (item.id == this.saleRepId) {
        roles.push('Sales Rep');
      }
      if (item.id == this.clientSalesRepId) {
        roles.push('Client Sales Rep');
      }
      this.saleRepEmails.push({
        id: item.id,
        email: item.email,
        roles: roles
      })
    }
  }

  copyCarrierName() {
    const name = this.displayInfo.carrier?.name ?? '';
    Utils.copyTextToClipboard(name, e => {
      if (e) {
        this.showErr('Cannot copy carrier name to clipboard.');
      } else {
        this.showSuccess('Carrier name was copied to clipboard.');
      }
    })
  }

  private buildDisplayInfo() {
    if (!this.route) return;
    this.displayInfo = {
      ...this.displayInfo,
      trailerNumber: this.route?.getTrailerNumber(),
      truckNumber: this.route?.getTruckNumber(),
      driverDownTime: this.getDriverDownTime(),
      customTracking: this.route?.getCustomTracking(),
      referenceNumber: this.route?.getReferenceNumber(),
      basePrice: this.route?.getBasePrice(),
      additionalCarrierCosts: this.route?.getAdditionalCarrierCost() || [],
    }
  }

  private async getData() {
    if (!this.route) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    let promises = [
      this.getDataCarrierBid(),
      this.carrierId ? this.getDataCarrier(this.carrierId) : Promise.resolve(null),
      this.driverId ? this.getDataDriver(this.driverId) : Promise.resolve(null),
      this.secondaryDriverId ? this.getDataSecondaryDriver(this.secondaryDriverId) : Promise.resolve(null)
    ];

    await Promise.all(promises).catch(e => {
      console.error(e);
    });
    this.isLoading = false;
  }

  private async getDataCarrierBid() {
    let jobId = this.route.getId();
    let url = `${Const.APIURI_CARRIER_BIDS}/get/detailByJobId?jobId=${jobId}`;
    const resp = await this.api.GET(url).toPromise();
    if (resp.data?.id) {
      this.displayInfo.carrierBidId = resp.data.id;
    }
  }

  public viewMcOrDot = '';

  private async getDataCarrier(id) {
    let url = `${Const.APIURI_CARRIERS}/${id}`;
    const resp = await this.api.GET(url).toPromise();
    if (resp.data?.id) {
      const basicInfo = resp.data.basicInfo;
      this.displayInfo.carrier = {
        _id: resp.data._id,
        id: resp.data.id,
        name: basicInfo?.name || "",
        contacts: basicInfo?.contacts?.map(contact => ({
          fullName: this.getFullName(contact),
          phoneNumber: this.getPhoneNumber(contact),
          email: contact.email,
          type: contact.type
        }))
      };
      if (basicInfo?.mc) {
        this.viewMcOrDot = `MC: ${basicInfo.mc}`;
      } else if (basicInfo?.dot) {
        this.viewMcOrDot = `DOT: ${basicInfo.dot}`;
      }
    }
  }

  private async getDataDriver(id) {
    let url = `${Const.APIURI_DRIVERS}/${id}`;
    const resp = await this.api.GET(url).toPromise();
    if (resp.data?.id) {
      const driver = resp.data;
      this.displayInfo.driver = {
        id: resp.data.id,
        fullName: this.getFullName(driver),
        phoneNumber: this.getPhoneNumber(driver),
        // email: driver?.email
      }
    }
  }

  private async getDataSecondaryDriver(id) {
    let url = `${Const.APIURI_DRIVERS}/${id}`;
    const resp = await this.api.GET(url).toPromise();
    if (resp.data?.id) {
      const driver = resp.data;
      this.displayInfo.secondaryDriver = {
        id: resp.data.id,
        fullName: this.getFullName(driver),
        phoneNumber: this.getPhoneNumber(driver),
        // email: driver?.email
      }
    }
  }

  isShowReferenceNumber() {
    return this.displayInfo.carrier?._id === Const.CarrierId_ForwardAir;
  }

  private getDriverDownTime() {
    //chỗ này chưa biết viết như nào
    //phải bổ sung thêm types và có khi phải đổi thành mảng
    const driverDownTime = this.route?.getDriverDownTime()
    let timezone = this.getTimezone(this.route.getStops());
    let from = DateUtil.displayLocalTime(driverDownTime?.from, { timezone, format: Const.FORMAT_GUI_DATETIME_V6 });
    let to = DateUtil.displayLocalTime(driverDownTime?.to, { timezone, format: Const.FORMAT_GUI_DATETIME_V6 });

    return {
      from: from,
      to: to,
      timezoneShort: DateUtil.timezoneStandardToUsShort(timezone)
    }
  }

  getTimezone(stops) {
    const stopInfos = stops.map(stop => stop.getDeliveryInfo());
    const firstPickupStop = (stopInfos || []).filter((stop) => stop.type == "PICKUP")[0];
    const pickupAddress = firstPickupStop?.addr ?? {};
    return pickupAddress?.metadata?.timeZoneStandard ?? 'America/Los_Angeles';
  }

  public getPhoneNumber(user) {
    if (!user || !user.phone) return '';
    return InputHelper.formatPhone(user.phone);
  }

  onBtnAssignCarrier() {
    DialogService.openFormDialog1(DispatchAssignCarrierAndDriver, {
      nzComponentParams: {
        jobId: this.route?.getId(),
        action: 'assign-carrier',
        vehicle: this.route.getRequiredVehicle(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  async onBtnEditDriver() {
    DialogService.openFormDialog1(DispatchAssignCarrierAndDriver, {
      nzComponentParams: {
        jobId: this.route?.getId(),
        action: 'assign-driver',
        vehicle: this.route.getRequiredVehicle(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  async onBtnEditSecondaryDriver() {
    DialogService.openFormDialog1(DispatchAssignCarrierAndDriver, {
      nzComponentParams: {
        jobId: this.route?.getId(),
        action: 'assign-secondary-driver',
        vehicle: this.route.getRequiredVehicle(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding assign-carrier-form',
    });
  }

  viewDriverAppStatus() {
    if (this.isDriverOnline) {
      const jobCode = this.route?.getCode();
      this.modalHelper.open(DriverAppStatus, {
        nzTitle: `Driver for the route <b>${jobCode}</b>`,
        nzFooter: null,
        nzComponentParams: {driverId: this.driverId, jobCode}
      });
    } else if (this.driverId) {
      this.dispatchLiveTracking.requestDriverOnlineStatus(this.driverId);
    }
  }

  onBtnEditTrailerNumber() {
    const jobId = this.route?.getId()
    DialogService.openFormDialog1(AddTrailerNumber, {
      nzComponentParams: {
        jobId: jobId,
        trailerNumber: this.route?.getTrailerNumber(),
        truckNumber: this.route?.getTruckNumber(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

  onBtnEditReferenceNumber() {
    const jobId = this.route?.getId()
    DialogService.openFormDialog1(AddReferenceNumber, {
      nzComponentParams: {
        jobId: jobId,
        referenceNumber: [this.route?.getReferenceNumber()],
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

  onBtnEditDriverDownTime() {
    let currentData = this.route?.getDriverDownTime();
    DialogService.openFormDialog1(DriverDownTime, {
      nzComponentParams: {
        model: currentData,
        timezone: this.getTimezone(this.route.getStops()),
        onSave: data => this.updateDriverDownTime(data)
      },
      nzClassName: 'modal-no-padding',
    });
  }

  updateDriverDownTime(data) {
    let url = `${Const.APIURI_JOBS}/${this.route.getId()}/update_driver_down_time`;
    this.api.PUT(url, data).subscribe(
      (resp) => {
        this.dispatchService.refresh();
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  getDriverOtp() {
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.route.getId()}/get-otp`);
    this.api.GET(url).subscribe(
      (resp) => {
        this.driverOtp = resp?.data?.otp
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  public onClickCreateCarrierBid() {
    let jobId = this.route?.getId();
    if (!jobId) return;
    this.router.navigate([this.routeAdminCarrierSales, 'create', jobId]);
  }

  public onClickViewCarrierBid() {
    if (!this.displayInfo?.carrierBidId) return;
    this.router.navigate([this.routeAdminCarrierSales, this.displayInfo.carrierBidId]);
  }

  public getCustomTrackingLink(){
    if(!this.displayInfo.customTracking) return undefined
    let links = this.displayInfo.customTracking;
    return links[links.length-1]
  }

  private showErrRequirePiC() {
    this.showErr(`Only the person in charge can perform this action!`);
  }

  public canEditItem() {
    if (!this.isAdmin) return false;
    if (this.isAdminReadOnlyRole) return false;

    // //nếu chưa có pic thì ai cũng được quyền edit
    // if (!item.pic) return true;
    // //nếu đã có pic thì chỉ pic mới được quyền edit.
    // return this.authUser._id == item.pic._id;

    return true;
  }

  onBtnAddCustomTracking(){
    if (!this.canEditItem()) {
      return this.showErrRequirePiC();
    }
    DialogService.openFormDialog1(AddCustomTracking, {
      nzComponentParams: {
        jobId: this.route?.getId(),
        links: this.route?.getCustomTracking(),
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: 'modal-no-padding add-custom-tracking-form',
    });
  }

  public saveDataForCarrierCost(jobId: string, data: CarrierCost) {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/carrier_cost`;
    return this.api.PUT(url, data);
  }

  public saveDataMarkAlreadyPaid(data: FormDataCarrierPaymentPlanning) {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/mark_carrier_paid`;
    return this.api.POST(url, data);
  }

  public updateDataNoteCarrierPaid(data: FormDataCarrierPaymentPlanning) {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/note_carrier_paid`;
    return this.api.POST(url, data);
  }

  get showUnassignCarrier(){
    return this.route?.getCarrierId() != null && this.route?.getStatus() != 'completed' && this.route?.getStatus() != 'inProgress';
  }

  onBtnUnassignCarrier() {
    this.confirmYesNo('Do you really want to unassign carrier?', () => {
      this.unassignCarrier();
    });
  }

  private unassignCarrier() {
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/unassign_carrier`, null).subscribe(
      resp => {
        Log.d('unassignCarrier done ', resp);
        let msg = 'Unassign Carrier done.';
        this.showDialog(msg, () => {});
        this.dispatchService.refresh();
      }, err => {
        this.showErr(err);
      }
    );
  }

  get shouldCreateAdditionalCarrierCost() {
    return !!this.route?.getAssignedCarrier()?.cost?.paid?.when;
  }

  onBtnCreateAdditionalCost() {
    if (!this.shouldCreateAdditionalCarrierCost) return;
    CarrierCostHelper.openModalCarrierCost({
      title: 'Create Additional Carrier Cost',
      currentData: {},
      submit: (jobId, data) => {
        return this.createAdditionalCarrierCost(this.jobId, data);
      },
      onError: err => UIHelper.showErr(err),
      onSuccess: resp => {
        let msg = 'Additional carrier cost has been added successfully.';
        this.showDialog(msg);
        this.dispatchService.refresh();
      },
    });
  }

  public createAdditionalCarrierCost(jobId: string, data: CarrierCost) {
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/additional_carrier_cost`;
    return this.api.POST(url, data);
  }

  saveAdditionalCarrierCost(item) {
    let id = item.id;
    let saveCarrierCost = (jobId: string, data: CarrierCost) => {
      const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/additional_carrier_cost/${id}`;
      return this.api.PUT(url, data);
    }
    return saveCarrierCost;
  }

  saveDataAdditionalCostMarkAlreadyPaid(item) {
    let id = item.id;
    let fn = (data) => {
      const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/mark_additional_carrier_cost_paid/${id}`;
      return this.api.POST(url, data);
    }
    return fn;
  }

  updateDataAdditionalCostNoteCarrierPaid(item) {
    let id = item.id;
    let fn = (note) => {
      const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/note_additional_carrier_cost_paid/${id}`;
      return this.api.POST(url, {note});
    }
    return fn;
  }

  shouldDisableMarkAlreadyPaidAdditional(item) {
    let additionalCosts = this.displayInfo?.additionalCarrierCosts || []
    let numOfCostAfterCurrentId = 0;
    for (let i=additionalCosts.length-1; i>=0; i--) {
      if (additionalCosts[i].id == item.id) {
        break;
      }
      numOfCostAfterCurrentId++;
    }
    return (item.cost?.paid?.when) && (!!numOfCostAfterCurrentId);
  }

}
