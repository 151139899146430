<div *ngIf="listChange.length > 0" class="list-history">
  <ng-container *ngIf="isCustomTimeline">
    <ng-container *ngFor="let item of listChange">
      <div class="d-relative">
        <div class="flex">
          <div class="timeline-item-tail"></div>
          <div class="flex-space-between group-history">
            <div class="timeline-content">
              <div class="d-relative">
                <div class="circle-timeline"></div>
              </div>
              <div>{{item.message}}</div>
              <div class="textUser"><span *ngIf="item.user?.fullName">By {{item.user.fullName}} -&nbsp;</span><button class="detail-btn" nz-button style="padding: 0;" nzType="link" (click)="openDetail(item._id)"><span>Details</span></button></div>
            </div>
            <div class="time">{{formatDate(item.eventTime)}}</div>
          </div>
          
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isCustomTimeline">
    <div class="timeline">
      <nz-timeline [nzMode]="mode" style="background: #ffffff;" >
        <ng-container *ngFor="let item of listChange">
          <nz-timeline-item nzLabel="{{formatDate(item.eventTime)}}">
            <div>{{item.message}}</div>
            <div  class="textUser"><span *ngIf="item.user?.fullName">By {{item.user.fullName}} -&nbsp;</span><button class="detail-btn" nz-button style="padding: 0;" nzType="link" (click)="openDetail(item._id)"><span>Details</span></button></div>
          </nz-timeline-item>
        </ng-container>
      </nz-timeline>
    </div>
  </ng-container>
</div>
<div *ngIf="listChange.length == 0 && isLoading == false">
  <nz-empty></nz-empty>
</div>
